@import "general";

#comment {
    flex: 6;

    ol {
        list-style: none;
        counter-reset: item;
    }

    li {
        counter-increment: item;
        margin-bottom: $spacer;
    }

    li:before {
        margin-right: 1.2em;
        content: counter(item);
        background: $light;
        border-radius: 100%;
        color: $dark;
        width: calc(var(--bs-body-line-height) * var(--font-size));
        text-align: center;
        display: inline-block;
    }
}

#contact-form-box {
    flex: 4;
}